.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

span.MJXc-display {
  margin: 0;
}

li > div {
  padding-left: 16px !important; 
}

ul > div > div > li > ul > div > div > li > div {
  padding-left: 32px !important; 
}

ul > div > div > li > ul > div > div > li > ul > div > div > li > div {
  padding-left: 64px !important; 
}

@keyframes fadeInOnLoad {
  0%,50% {opacity: 0,}
  100% {opacity: 1,}
}



.loader {
  position: relative;
  margin: 0px auto;
  width: 100px;
}

.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes 
rotate {  100% {
 -webkit-transform: rotate(360deg);
 transform: rotate(360deg);
}
}

@keyframes 
rotate {  100% {
 -webkit-transform: rotate(360deg);
 transform: rotate(360deg);
}
}

@-webkit-keyframes 
dash {  0% {
 stroke-dasharray: 1, 200;
 stroke-dashoffset: 0;
}
 50% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -35;
}
 100% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -124;
}
}

@keyframes 
dash {  0% {
 stroke-dasharray: 1, 200;
 stroke-dashoffset: 0;
}
 50% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -35;
}
 100% {
 stroke-dasharray: 89, 200;
 stroke-dashoffset: -124;
}
}

@-webkit-keyframes 
color {  100%, 0% {
 stroke: #d62d20;
}
 40% {
 stroke: #0057e7;
}
 66% {
 stroke: #008744;
}
 80%, 90% {
 stroke: #ffa700;
}
}

@keyframes 
color {  100%, 0% {
 stroke: #d62d20;
}
 40% {
 stroke: #0057e7;
}
 66% {
 stroke: #008744;
}
 80%, 90% {
 stroke: #ffa700;
}
}

.wrapperClassName {
  color: inherit;

}
.toolbarClassName {
  color: inherit;
}

.editorClassName {
  color: inherit;
  padding: 0px 12px;
}