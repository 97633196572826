body {
  margin: 0;
  width: 100vw;
  /*background: linear-gradient(230deg,#282444 .99%, #151a1d 99.12%)*/
  /*background: linear-gradient(346deg,#020304 .99%, #313239 99.12%) /*linear-gradient(346deg,#365fdb .99%, #2b2036 99.12%)*/
  background: #0c0d0f;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

body {
  /* font-family: 'Open Sans', sans-serif; */
}

code {
  background-color: rgba(0,0,0,0.7);
  padding: 6px 8px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  line-height: 2.5em;
  font-size: 0.85em;
}

button, a {
  /*text-transform: none !important;*/
}


.embed-container {
  --video--width: 1920;
  --video--height: 1080;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor,
.output {
    min-height: 100%;
    position: relative;
    border: solid 1px #ccc;
}